<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../public/images/privacy/head.jpg" width="100%">
    <div class="center">隐私权政策</div>
    <div class="brode">
        <P>最新修订日期：2018年08月30日</P>
      <P>重要须知：</P>
      <P>保护您的（又称“用户”）个人信息安全是本公司及其关联公司的一项基本原则。本《隐私协议》（下称“协议”）旨在说明本公司将如何收集、存储、使用、披露您的个人信息。在使用本公司所提供的所有网站、软件或其他服务前，请仔细阅读这些条。
        本公司会不时更新本协议。您在同意本协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本公司服务协议不可分割的一部分。</P>
      <p>本公司尊重并保护所有使用本公司服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本公司会按照本隐私权政策的规定使用和披露您的个人信息。但本公司将以高度的勤勉、审慎义务对待这些信息。
        除本隐私权政策另有规定外，在未征得您事先许可的情况下，本公司不会将这些信息对外披露或向第三方提供。</p>
      <p>一、适用范围</p>
      <p>1. 在您注册或激活可以登录本公司的帐户时，您在本公司提供的个人注册信息（应法律法规要求需公示的企业名称等相关工商注册信息以及自然人的信息除外）；</p>
      <p>2. 在您使用本公司服务，或访问本公司网页时，本公司自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；如您下载或使用本公司或其关联公司移动客户端软件，或访问移动网页使用本公司平台服务时，本公司可能会读取与您位置和移动设备相关的信息，包括但不限于设备型号、设备识别码、操作系统、分辨率、电信运营商等。</p>
      <p>3. 本公司通过合法途径从商业伙伴处取得的用户个人数据。</p>
      <p>二、信息使用</p>
      <p>1. 本公司不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本公司（含本公司关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</p>
      <p>2. 本公司亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本公司平台用户如从事上述活动，一经发现，本公司有权立即终止与该用户的服务协议。</p>
      <p>3. 为服务用户的目的，本公司或其关联公司可能通过使用您的个人信息，向您提供您可能感兴趣的信息，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或者与本公司合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。</p>
      <p>4. 本公司可以使用您的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与本公司或其关联公司协议、政策或规则的行为，以保护您、其他本公司用户，或本公司或其关联公司合法权益。</p>
      <p>三、信息披露</p>
      <p>在如下情况下，本公司将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</p>
      <p>（1）经您事先同意，向第三方披露；</p>
      <p>（2）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
      <p>（3）如您出现违反中国有关法律、法规或者本公司服务协议或相关规则的情况，需要向第三方披露；</p>
      <p>（4）为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
      <p>（5）其它本公司根据法律、法规或者网站政策认为合适的披露。</p>
      <p>四、信息存储和交换</p>
      <p>本公司收集的有关您的信息和资料将保存在本公司及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本公司收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>
      <p>五、COOKIE的使用</p>
      <p>1. 在您未拒绝接受COOKIES的情况下，本公司会在您的计算机上设定或取用COOKIES，以便您能登录或使用依赖于COOKIES的本公司平台服务或功能。本公司使用COOKIES可为您提供更加周到的个性化服务，包括推广服务。</p>
      <p>2. 您有权选择接受或拒绝接受COOKIES。您可以通过修改浏览器设置的方式拒绝接受COOKIES。但如果您选择拒绝接受COOKIES，则您可能无法登录或使用依赖于COOKIES的本公司平台服务或功能。</p>
      <p>3. 通过本公司所设COOKIES所取得的有关信息，将适用本政策。</p>
      <p>六、信息安全</p>
      <p>1. 您的帐户均有安全保护功能，请妥善保管您的帐户及密码信息。本公司将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。</p>
      <p>2. 如您发现自己的个人信息泄密，尤其是你的帐户及密码发生泄露，请您立即联络本公司客服，以便本公司采取相应措施。</p>
      <p>我们可能适时修订本《隐私政策》的条款，该等修订构成本《隐私政策》的一部分。如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。</p>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "privacy",
  components: {
    elMenu,foot,navigation
  },
}
</script>

<style scoped>
.center{
  text-align: center;
  font-size:24px;
  margin-top: 120px;
}
.brode{
  width:70%;
  height:1500px;
  margin: 0px auto;
}

</style>